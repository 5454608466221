<template>
    <v-expansion-panels>
      <v-expansion-panel v-for="report in reports" :key="report.title" class="mt-2">
        <v-expansion-panel-header color="#E9E9E9">
          <span style="font-size: 16px; font-weight: 500;"> {{ report.title }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4" >
          <div v-if="report.attr" class="d-flex align-center" style="gap: 10px">
            <v-icon>mdi-calendar</v-icon>
            <v-menu
              v-model="report.menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on"> {{ dateFromComputed }}</v-chip>
              </template>
              <v-date-picker
                v-model="dateFrom"
                @input="report.menu1 = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="report.menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on"> {{ dateToComputed }}</v-chip>
              </template>
              <v-date-picker
                v-model="dateTo"
                @input="report.menu2 = false"
              ></v-date-picker>
            </v-menu>
            <v-btn
              color="#007450"
              max-width="225"
              rounded
              outlined
              :loading="loading"
              :disabled="!dateTo || !dateFrom"
              @click="getReport(report)"
            >
              Применить
            </v-btn>
          </div>
          <div v-else>
            <v-icon>mdi-file-excel</v-icon>
            <v-btn
              class="text-decoration-underline text--black"
              text
              plain
              :loading="loading"
              @click="getReport(report)"
            >
              {{ `${report.title} на ${currentDate}.xls`}}
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
import moment from 'moment';

export default {
  name: 'Reports',
  data() {
    return {
      reports: [
        {
          title: 'Отчет по продажам',
          attr: 'dates',
          url: '/clever-market-report/get-report-sales-xls',
        },
        {
          title: 'Отчет по остаткам',
          attr: null,
          url: '/clever-market-report/get-product-balances-xls',
        },
        {
          title: 'Отчет по заказам',
          attr: 'dates',
          url: '/clever-market-report/get-report-orders-xls',
        },
        {
          title: 'Отчет по товарам',
          attr: null,
          url: '/clever-market-report/get-report-products-xls',
        },
        {
          title: 'Отчет по клиентам',
          attr: 'dates',
          url: '/clever-market-report/get-report-users-xls',
        },
      ],
      dateFrom: null,
      dateTo: null,
      loading: false,
    };
  },
  computed: {
    dateFromComputed() {
      if (!this.dateFrom) {
        return 'Укажите дату С';
      }
      return `C ${this.formatDate(this.dateFrom)}`;
    },
    dateToComputed() {
      if (!this.dateTo) {
        return 'Укажите дату По';
      }
      return `по ${this.formatDate(this.dateTo)}`;
    },
    currentDate() {
      return moment(new Date()).format('DD-MM-YYYY');
    },

  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    getReport(report) {
      let { url } = report;
      if (report.attr) {
        const query = `?StartDate=${this.dateFrom}&EndDate=${this.dateTo}`;
        url += query;
      }
      this.loading = true;
      this.$reportService
        .get(url, { responseType: 'blob' })
        .then((res) => {
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(res);
          a.download = report.title;
          a.click();
          this.loading = false;
        });
    },
  },
};
</script>
